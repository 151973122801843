import React, { Component, useEffect, useRef } from "react";
import BackgroundImage from 'gatsby-background-image'
import { scroll } from "../theme";
import gsap from "gsap/gsap-core";
import { window } from "browser-monads";
import CircleLink from "./circle-link";
import utils from "./utils";
import { DEFAULT_INNER_COMPONENT_DELAY_MS } from "../constants";
import WebImage from "./image";

export default function FeaturedPerson({ subtitle, title, description, image, subtext, slug, index, link }) {


  const ref = useRef();
  const titleEl = useRef();
  const subEl = useRef();

  const desktopAnimation = (index) => {
    window.locomotiveScroll.stop()
    window.locomotiveScroll.scrollTo(`#person-${index}`, {
      duration: 100
    })
  }

  useEffect(() => {
    setTimeout(() => {
      gsap.from(titleEl.current, {
        scrollTrigger: {
          trigger: ref.current,
          scroller: scroll.container,
          start: "top top+=200"
        },
        duration: 1.5,
        delay: 0.2,
        y: 50,
        opacity: 0,
        ease: "power2.out"
      })
      gsap.from(subEl.current, {
        scrollTrigger: {
          trigger: ref.current,
          scroller: scroll.container,
          start: "top top+=200"
        },
        duration: 1.5,
        opacity: 0,
        delay: 0.5,
        ease: "power2.out"
      })
    }, DEFAULT_INNER_COMPONENT_DELAY_MS)
  }, [])

  return (
    <section id={`person-${index}`} ref={ref} className="content-grid-item bg-sand lg:h-screen flex flex-col lg:flex-row">
      <WebImage image={image} outerCssClass={'lg:flex-0 mobile-landscape-h-fix h-75vh lg:h-screen w-full lg:w-1/2'} innerCssClass="w-full h-full" />
      <div className={`flex flex-0 flex-col px-4 lg:px-12 p-8 lg:p-12 relative w-full lg:w-1/2 sand-section-max-height-fix lg:h-screen ${subtitle ? 'justify-between' : 'justify-between lg:justify-end'}`}>
        {
          subtitle && <span className="font-mabry tracking-wider uppercase text-sm">{subtitle}</span>
        }
        <div ref={titleEl} className="lg:absolute lg:top-1/2 lg:transform lg:-translate-y-1/2 pt-20 lg:pt-0">
          <h3 className="font-sc capitalize text-lg lg:text-xl mb-4 lg:mb-0">{title}</h3>
          {subtext && <p className="block lg:hidden font-mabry tracking-wider uppercase text-sm mb-12">{subtext}</p>}
        </div>
        <div ref={subEl} className="max-w-lg">
          {subtext && <p className="hidden lg:block font-mabry tracking-wider uppercase text-sm mb-12">{subtext}</p>}
          <p className="font-sailec">{description}</p>
          {
            link && <CircleLink cssClass="mt-4" title="Read More" isLink={true} to={`/about/${slug}`} duration={utils.getScrollToDuration()} onPress={() => {
              utils.isDesktop() ? desktopAnimation(index) : utils.fadeOutFromPurple()
            }} />
          }
        </div>
      </div>
    </section>
  )
}
