import React, { useEffect } from "react";
import Layout from '../components/layout'
import { window } from 'browser-monads'
import { graphql } from "gatsby"
import utils from '../components/utils'
import ContactForm from '../components/contact-form'
import PageHeader from "../components/page-header";
import { scroll } from "../theme";
import { gsap } from 'gsap'
import SEO from "../components/seo";
import FeaturedPerson from "../components/featured-person";
import { DEFAULT_INNER_COMPONENT_DELAY_MS } from "../constants";

export default function AboutPage({ data }) {
  useEffect(() => {
    utils.resetPage()

    setTimeout(() => {
      window.scrollTrigger.create({
        trigger: "#about",
        start: "top top+=100px",
        end: "bottom top+=100px",
        scroller: "#scroll-container",
        onEnter() {
          utils.fadeNav(true)
        },
        onEnterBack() {
          utils.fadeNav(true)
        },
        onLeave() {
          utils.fadeNav(false)
        },
        onLeaveBack() {
          utils.fadeNav(false)
        }
      })
      gsap.from("#about .about-content", {
        scrollTrigger: {
          trigger: "#about",
          scroller: scroll.container,
          start: "top center"
        },
        duration: 1.5,
        delay: 0.2,
        y: 50,
        opacity: 0,
        ease: "power2.out"
      })
    }, DEFAULT_INNER_COMPONENT_DELAY_MS)
  }, [])

  const people = data.allContentfulPerson.nodes
  const page = data.contentfulPageAbout

  return (
    <Layout>
      <SEO
        title={page.meta.title}
        description={page.meta.description}
        image={page.meta.image.fluid.src}
      />
      <PageHeader image={page.headerImage.fluid} title={page.title} />

      <section id="about" className="bg-dark px-4 lg:px-0 min-h-screen text-sand flex items-center">
        <div className="max-w-2xl mx-auto about-content">
          <h2 className="font-sailec lg:text-lg text-center">
            {page.mainText.mainText}
          </h2>
        </div>
      </section>
      {
        people.map((person, index) => <FeaturedPerson
          title={person.name}
          subtext={person.position}
          description={person.shortDescription.shortDescription}
          image={person.image.fluid}
          index={index}
          link={true}
          slug={person.slug}
        />)
      }
      <ContactForm />
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulPageAbout {
      title
      mainText {
        mainText
      }
      headerImage {
        fluid(quality: 95, maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      meta {
        title
        description
        image {
          fluid {
            src
          }
        }
      }
    }
    allContentfulPerson(sort: {fields: order, order: ASC}) {
      nodes {
        position
        slug
        name
        image {
          fluid(quality: 90, maxHeight: 1080) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        shortDescription {
          shortDescription
        }
      }
    }
  }
`